/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
          }
          social {
            github
            medium
            linkedin
            dev
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social
  const avatar = data?.avatar?.childImageSharp?.fixed

  return (
    <div>
      <div className="bio">
        {avatar && (
          <Image
            fixed={avatar}
            alt={author?.name || ``}
            className="bio-avatar"
            imgStyle={{
              borderRadius: `50%`,
            }}
          />
        )}
        {author?.name && (
          <p>
            This website is owned by {author.name} who is a software developer.
            He writes here about things that he is interested in or is currently learning.<br/> You can find him also at{` `}
            <a href={`https://github.com/${social?.github}`} target="_blank" rel="noreferrer">Github</a>{` and contact on `}
            <a href={`https://linkedin.com/in/${social?.linkedin}`} target="_blank" rel="noreferrer">LinkedIn</a>.
          </p>
        )}
      </div>
    </div>
  )
}

export default Bio
